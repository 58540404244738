<template>
  <div style="position: fixed;bottom: 20px;width: 100%;font-size: 12px;text-align: center;"> 
    <span>增值电信业务经营许可证编号:川B2-20230065</span>
    <a href="https://beian.miit.gov.cn/" style="margin-left: 8px;color: white;">蜀ICP备2022028475号-1</a>
    <a href="javascript:; " @click="getInfo" style="margin-left: 8px;color: white;">出版物经营许可证:川新出发审字第M661号</a>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Footer',
  methods: {
    getInfo() {
      this.$router.push({name: 'info'})
    }
  }
}
</script>