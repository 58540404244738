<template>
  <div class="home">
    <div style="display: flex;justify-content: space-around;padding-top: 10%;"> 
      <div>
        <img src="/img/main_app.jpeg"/>
      </div>
      <div>
        <div style="font-size: 32px;">四川泛美云典籍科技有限公司</div>
        <div style="margin-top: 16px;font-size: 20px;"> 
          <div style="display: flex;margin-top: 8px;"> 
            <div style="width: 50%;">云碎片</div>
            <div style="width: 50%;">云班课</div>
          </div>
          <div style="display: flex;margin-top: 8px;"> 
            <div style="width: 50%;">云教材</div>
            <div style="width: 50%;">在线学习</div>
          </div>
        </div>
        <div style="margin-top: 16px;display: flex;flex-direction: column;align-items: center;"> 
          <a class="btn" href="https://sj.qq.com/appdetail/com.aefree.fmcloud">安卓版下载</a>
          <a class="btn" href="https://apps.apple.com/cn/app/%E6%B3%9B%E7%BE%8E%E6%95%99%E8%82%B2/id1530885060">iOS版下载</a>
          <a class="btn" href="https://cloud.fmyundianji.com/web/textbook_list_blank">书架</a>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'
export default {
  name: 'HomeView',
  components: {
    Footer
  },
  methods: {
    getInfo() {
      this.$router.push({name: 'info'})
    }
  }
}
</script>
<style lang="less" scoped>
.home{
  color: white;
  height: 100%;
  background-image: url('../../public/img/pc.jpg');
  background-repeat: no-repeat;
}
.btn{
  border: none;
  text-align: center;
  background: #fff;
  color: #00898b;
  width: 196px;
  height: 54px;
  line-height: 50px;
  border-radius: 54px;
  font-size: 20px;
  display: block;
  margin: 10px 0 15px 0;
  cursor: pointer;
}
</style>
