<template>
  <div class="home">
    <div style="display: flex;justify-content: space-around;padding-top: 10%;"> 
      <div style="text-align: center;">
        <img src="/img/1.jpg" style="width: 600px"/>
      </div>
      <div style="text-align: center;">
        <img src="/img/2.png" style="width: 600px"/>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Footer from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    Footer
  },
  methods: {
    getInfo() {
      this.$router.push({name: 'info'})
    }
  }
}
</script>
<style lang="less" scoped>
.home{
  color: white;
  height: 100%;
  background-image: url('../../public/img/pc.jpg');
  background-repeat: no-repeat;
}
.btn{
  border: none;
  text-align: center;
  background: #fff;
  color: #00898b;
  width: 196px;
  height: 54px;
  line-height: 50px;
  border-radius: 54px;
  font-size: 20px;
  display: block;
  margin: 10px 0 15px 0;
  cursor: pointer;
}
</style>
